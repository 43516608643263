/* Widths */
.w-100 {
  width: 100% !important;
}

/* Backgrounds */
.bg-white {
  background: #fff;
}

.bg-danger {
  background: #ff4d4f;
}

/* Fonts */
.fs-1 {
  font-size: 12px;
}

.fs-2 {
  font-size: 14px;
}

.fs-3 {
  font-size: 16px;
}

.fs-4 {
  font-size: 18px;
}

.fs-5 {
  font-size: 20px;
}

.fs-6 {
  font-size: 24px;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fst-italic {
  font-style: italic;
}

/* Alignment */
.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

/* Margins */
.m-a {
  margin: auto;
}

.mh-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mh-a {
  margin-left: auto;
  margin-right: auto;
}

/* Margins (Top) */
.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

/* Margins (Left) */
.ml-0 {
  margin-left: 0px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

/* Margins (Bottom) */
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

/* Margins (Right) */
.mr-0 {
  margin-right: 0px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

/*padding*/
.p-12 {
  padding: 12px !important;
}

/* Paddings (Top) */

.pt-0 {
  padding-top: 0px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

/* Paddings (Right) */

.pr-0 {
  padding-right: 0px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

/* Paddings (Bottom) */

.pb-0 {
  padding-bottom: 0px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

/* Paddings (Left) */

.pl-0 {
  padding-left: 0px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

/* Other */
.pointer {
  cursor: pointer;
}

/*Colors */

.c-divider {
  color: #c1c1c1;
}

.c-subtext {
  color: #7b7b7b !important;
}

.c-grey {
  color: black;
  /* color: #5b5b5b; */
}

.c-light {
  color: rgba(0, 0, 0, 0.4);
}

.c-danger {
  color: #ff4d4f;
}
