.layout {
  min-height: 100vh;
}

.mainContent {
  /* padding: 10px 0 0; */
  padding: 0 16px;
  margin-top: 64px;
}

.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: white;
  padding: 0px 0px 0px 0px;
  box-shadow: 2px 0px 0px 0px rgba(40, 56, 86, 0.1);
}

.header .logo {
  vertical-align: middle;
  height: 20px;
  margin-left: 24px;
  cursor: pointer;
}

.header .nav {
  margin: auto;
  text-align: center;
  justify-content: center;
  font-size: 18px;
}

.nav .icon {
  font-size: 18px !important;
}

.subMenuItem {
  margin: 0px !important;
  height: 50px !important;
  line-height: 47px !important;
  font-size: 18px !important;
  border-bottom: 1px solid #ebebeb;
}

.subMenuIcon {
  font-size: 18px !important;
  margin-right: 10px;
}

.header .avatar {
  text-align: right;
}

.slider {
  /* background: rgba(72, 103, 177, 0.8); */
  background: transparent;
  border-right: 1px solid #e8e8e8;
  /* position: fixed; */
  /* overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0; */
}

.menu {
  background: transparent;
}

.menu .item {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}

.subMenuAvatar {
  margin-right: 10px;
  margin-top: -5px;
  width: 18px;
  height: 18px;
}

.navItem {
  height: 50px;
  line-height: 40px;
  font-size: 16px;
}

.navItem .icon {
  font-size: 15px !important;
  margin-right: 15px !important;
}

@media (max-width: 991px) {
  .nav .icon {
    margin-right: 0px;
  }
}

.loadingContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
