.modelChoose {
  padding: 24px;
  width: 300px;
  border-radius: 10px;
  margin: auto;
  font-size: 14px;
}

.chatBox {
  height: 500px;
}

.chatArea {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.chatArea .system {
  padding: 12px 24px 12px 12px;
  background: #ffffff;
  line-height: 27px;
}

.chatArea .user {
  padding: 12px 24px 12px 12px;
  line-height: 27px;
  background: #f6f7f9;
}

.summaryBox {
  overflow-y: auto;
  font-size: 13px;
  line-height: 1.5;
  height: 250px;
}
