.loginContainer {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginForm {
  width: 328px;
}
