.txt-primary {
  color: var(--primary-color);
}

.prefixIcon {
  color: rgba(0, 0, 0, 0.45);
  font-size: 15px;
}

/* Inputs */
.ant-input-lg {
  padding: 14px 16px;
  font-size: 16px;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: inherit;
}

.ant-input-affix-wrapper {
  border-radius: 2px;
}

.ant-input-affix-wrapper-lg {
  border-radius: 5px;
  padding: 5.2px 11px;
}

.ant-input-group .ant-input {
  height: 38px;
}

.ant-input-search-large .ant-input-search-button {
  height: 38px;
}

/* Buttons */
.ant-btn-lg {
  height: 48px;
  padding: 0px 16px;
  border-radius: 5px;
  font-size: 18px;
}

.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
}

.ant-btn-link {
  color: var(--primary-color);
}

.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--primary-hover-color);
}

/* Menu */
.ant-layout-header .ant-menu {
  line-height: 64px;
  background: transparent;
}

.ant-menu-horizontal {
  border: none;
}

.ant-select-lg {
  font-size: 14px;
}

.container .ant-form-item {
  margin-bottom: 0;
}

@media only screen and (max-width: 576px) {
  .Nav_mainContent__1Ejlg {
    padding: 0 12px !important;
  }

  .ant-table-content {
    overflow-x: auto;
  }

  .ant-picker-large {
    padding-left: 0 !important;
  }
}

.ant-menu-item-group-title {
  padding: 12px 16px 2px 16px;
  font-size: 10px;
}

/* Upload */
.ant-upload:hover .ant-avatar {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-upload:hover .edit-upload {
  visibility: visible;
}

/* Tabs */
.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0px;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0px 0;
}

/* Menu */

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 0px;
}

/* Avatar */

.ant-avatar {
  border: none;
}

/* Statistic */

.ant-statistic .ant-statistic-skeleton {
  padding-top: 0px;
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
  margin-block-start: 14px;
  margin-block-end: 14px;
}

.ant-statistic .ant-statistic-content .ant-statistic-content-value {
  min-height: 44px;
  padding-top: 4px;
}
