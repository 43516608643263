.header {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-bottom: 25px !important;
  justify-content: space-between;
  align-items: center;
}

.resend {
  color: #4867b1;
  cursor: pointer;
}
