.logo {
  height: 20px;
  width: 20px;
}

.logo.empty {
  background-color: var(--empty-avatar-bg-color);
  color: var(--empty-avatar-text-color);
  vertical-align: middle;
  font-size: 10px;
  line-height: 20px;
  font-weight: 500;
}

.logo.emptyNode {
  background-color: var(--empty-logo-bg-color);
  color: var(--empty-logo-text-color);
  vertical-align: middle;
  font-size: 10px;
  line-height: 20px;
  font-weight: 500;
}

.logoLg {
  height: 32px;
  width: 32px;
}

.logoLg.empty {
  background-color: var(--empty-avatar-bg-color);
  color: var(--empty-avatar-text-color);
  vertical-align: middle;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
}

.logoLg.emptyNode {
  background-color: var(--empty-logo-bg-color);
  color: var(--empty-logo-text-color);
  vertical-align: middle;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
}

.logoXl {
  height: 56px;
  width: 56px;
}

.logoXl.empty {
  background-color: var(--empty-logo-bg-color);
  color: var(--empty-logo-text-color);
  vertical-align: middle;
  font-size: 24px;
  line-height: 56px;
  font-weight: 500;
}

.logoXXl {
  height: 64px;
  width: 64px;
}

.logoXXl.empty {
  background-color: var(--empty-logo-bg-color);
  color: var(--empty-logo-text-color);
  vertical-align: middle;
  font-size: 32px;
  line-height: 64px;
  font-weight: 500;
}

.logoXXXl {
  height: 140px;
  width: 140px;
}

.logoXXXl.empty {
  background-color: var(--empty-avatar-bg-color);
  color: var(--empty-avatar-text-color);
  vertical-align: middle;
  font-size: 64px;
  line-height: 140px;
  font-weight: 500;
}

.logoXXXl.emptyNode {
  background-color: var(--empty-logo-bg-color);
  color: var(--empty-logo-text-color);
  vertical-align: middle;
  font-size: 64px;
  line-height: 140px;
  font-weight: 500;
}
