.legalContainer{
  max-width: 1000px;
  padding: 30px 24px 30px 24px;
  margin: auto;
  font-size: 15px;
  line-height: 30px;
}

.updated{
  font-size: 12px;
  text-align: left;
  margin-top: 30px;
  font-style: italic;
}