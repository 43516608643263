body {
  margin: 0;
  background: var(--bg-color) !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.container {
  padding: 24px;
  max-width: 1500px;
  margin: 0px auto 16px auto;
}

.container-md {
  padding: 24px;
  max-width: 1000px;
  margin: 0px auto 16px auto;
}

.container-sm {
  padding: 24px;
  max-width: 480px;
  margin: 0px auto 16px auto;
}

.loading-container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 50px 50px;
  text-align: center;
}

.b-shadow {
  box-shadow: 0 0.8rem 0.8rem 0 rgb(17 17 17 / 8%);
}

.ant-upload:hover .ant-avatar {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.45);
}

.edit-upload {
  visibility: hidden;
  position: absolute;
  width: 90px;
  height: 20px;
  display: flex !important;
  top: 35px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.ant-upload:hover .edit-upload {
  visibility: visible;
}

.box {
  font-size: 17px;
  padding: 15px;
  border: 1px black dashed;
  /* text-align: center; */
}

.box.select {
  border: 1px var(--primary-color) solid;
  background-color: var(--primary-color);
  color: #ffffff;
}

.stat-card {
  padding: 12px 20px;
  background-color: white;
}

.link {
  color: var(--primary-color) !important;
  cursor: pointer;
}

a {
  color: var(--primary-color) !important;
}

a:hover {
  color: var(--primary-color) !important;
}

:root {
  --primary-color: #4867b1;
  --primary-hover-color: #6c87bd;
  --bg-color: #f0f2f5;

  --empty-avatar-bg-color: #516cac;
  --empty-avatar-text-color: #ffffff;

  --empty-logo-bg-color: #e85036;
  --empty-logo-text-color: #ffffff;
}
