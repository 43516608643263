.system {
  font-size: 16px;
  line-height: 27px;
  padding: 12px 0px;
  font-weight: 400;
}

.user {
  font-size: 16px;
  line-height: 27px;
  padding: 12px 0px;
  font-weight: 600;
}

.tool {
  padding: 12px 12px;
  font-size: 13px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin: 10px 0px 15px 32px;
}

.summary {
  padding: 12px 12px;
  font-size: 13px;
  border: 1px solid lightgray;
  background-color: #e8e8e8;
  border-radius: 10px;
  margin: 10px 0px 15px 32px;
}

.translation {
  font-size: 13px;
  opacity: 0.5;
}

.time {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  color: grey;
  margin-top: 5px;
}

.eventBox {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px;
}

.eventBox .title {
  font-size: 12px;
  font-weight: 500;
}

.eventBox .at {
  font-size: 10px;
  margin-top: 5px;
  font-weight: 400;
  color: grey;
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px;
}

.header .key {
  font-size: 12px;
  font-weight: 500;
  color: grey;
}

.header .val {
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
}
