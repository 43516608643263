.box {
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
}

.box .title {
  font-size: 18px;
}

.box .sub {
  font-size: 10px;
}

.box .val {
  font-size: 26px;
  font-weight: 400;
  margin-top: 8px;
}

.info {
  color: #4867b1;
  cursor: pointer;
}

.logBox {
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  padding: 10px 20px 0px 20px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
}
